var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();_vm.action === 'create' ? _vm.createItem() : _vm.editItem()}}},[_c('v-container',[_c('Title',{staticClass:"mb-8",attrs:{"title":_vm.action === 'create' ? 'Agregar moneda' : 'Actualizar moneda'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.availableCurrencies,"label":"Moneda *","item-value":"value","item-text":"text","outlined":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('vuetify-money',{attrs:{"label":"Tasa de cambio *","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            },"properties":{
              hint: '',
            }},model:{value:(_vm.rate),callback:function ($$v) {_vm.rate=$$v},expression:"rate"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"pa-5",attrs:{"small":"","color":"primary","type":"submit","depressed":"","disabled":!_vm.validation,"loading":_vm.loading}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }