<template>
  <div>
    <v-form
      ref="form"
      @submit.prevent="action === 'create' ? createItem() : editItem()"
    >
      <v-container>
        <Title
          :title="action === 'create' ? 'Agregar moneda' : 'Actualizar moneda'"
          class="mb-8"
        />
        <v-row>
          <v-col cols="12">
            <v-select
              v-model="code"
              :items="availableCurrencies"
              label="Moneda *"
              item-value="value"
              item-text="text"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <vuetify-money
              v-model="rate"
              label="Tasa de cambio *"
              placeholder="0.00"
              :outlined="true"
              :clearable="true"
              :backgroundColor="
                $vuetify.theme.currentTheme.vuetifyMoneyBackground
              "
              :options="{
                locale: 'ja-JP',
                prefix: 'RD$. ',
                suffix: '',
                length: 9,
                precision: 2,
              }"
              :properties="{
                hint: '',
              }"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              small
              color="primary"
              class="pa-5"
              type="submit"
              depressed
              :disabled="!validation"
              :loading="loading"
            >
              Guardar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import Title from "../Title.vue";

export default {
  name: "CurrencyForm",
  components: {
    Title,
  },
  props: ["action"],
  data() {
    return {
      id: "",
      code: "",
      rate: "",
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser", "availableCurrencies"]),
    validation() {
      return !(!this.code || !this.rate);
    },
  },
  methods: {
    ...mapMutations(["setAlert", "pushCurrency", "updateCurrency"]),
    async createItem() {
      try {
        this.loading = true;
        const res = await axios.post("/api/currency", {
          code: this.code,
          rate: this.rate,
          doctor: this.currentUser.doctor,
        });
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.pushCurrency(item);
        this.$emit("item-created", item);
        this.$emit("submited");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async editItem() {
      try {
        this.loading = true;
        const res = await axios.put(`/api/currency/${this.id}`, {
          id: this.id,
          code: this.code,
          rate: this.rate,
          doctor: this.currentUser.doctor,
        });
        const { item, message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.updateCurrency(item);
        this.$emit("item-updated", item);
        this.$emit("submited");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error?.response?.data?.message,
        });
      } finally {
        this.loading = false;
      }
    },
    setFormValues(obj) {
      this.id = obj?.id || null;
      this.code = obj?.code || "";
      this.rate = obj?.rate || "";
    },
  },
};
</script>
